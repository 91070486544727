<template>
  <ion-page>
    <page-header title="Profil" />
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-lg="6" offset="0" offset-lg="3">

            <h2>Dein Profil</h2>
            <ion-card>
              <ion-card-header>
                <ion-avatar class="avatar">
                  <img src="https://via.placeholder.com/96" />
                </ion-avatar>
                <ion-card-subtitle>Admin</ion-card-subtitle>
                <ion-card-title>Jane Doe</ion-card-title>
              </ion-card-header>

              <ion-card-content>
                Meine Motivation: Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Ad nostrum ducimus beatae deleniti in voluptas ipsum minima temporibus fugiat mollitia aperiam.

                <div class="mt-1 align-row align-items-center">
                  <ion-icon :icon="locateOutline" class="icon-24"></ion-icon> Berlin
                </div>

                <div class="mt-1 align-row align-items-center">
                  <ion-icon :icon="rocketOutline" class="icon-24"></ion-icon> 32 Jahre alt
                </div>

                <ion-button class="mt-2">
                  Bearbeiten
                  <ion-icon slot="start" :icon="pencilOutline"></ion-icon>
                </ion-button>
              </ion-card-content>

            </ion-card>

            <h2>Aktionen</h2>
            <ion-button @click="logout()" color="danger">
              Logout
              <ion-icon slot="start" :icon="logOutOutline"></ion-icon>
            </ion-button>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAvatar, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { logOutOutline, locateOutline, rocketOutline, pencilOutline } from 'ionicons/icons';
import PageHeader from '@/components/PageHeader.vue'

import { inject } from 'vue'

export default  {
  name: 'PageProfile',
  components: { IonContent, IonPage, IonButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAvatar, IonGrid, IonCol, IonRow, PageHeader },
  setup () {
    const logout = inject('logout');
    return {
      logOutOutline, locateOutline, rocketOutline, pencilOutline,
      logout
    }
  }
}
</script>

<style scoped>
.avatar {
  width: 96px;
  height: 96px;
  float: right;
}
</style>
