
import { IonPage, IonContent, IonButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAvatar, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { logOutOutline, locateOutline, rocketOutline, pencilOutline } from 'ionicons/icons';
import PageHeader from '@/components/PageHeader.vue'

import { inject } from 'vue'

export default  {
  name: 'PageProfile',
  components: { IonContent, IonPage, IonButton, IonIcon, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonAvatar, IonGrid, IonCol, IonRow, PageHeader },
  setup () {
    const logout = inject('logout');
    return {
      logOutOutline, locateOutline, rocketOutline, pencilOutline,
      logout
    }
  }
}
